import { defineRule } from "vee-validate";

defineRule("required", (value, [name]) => {
  if ((!value && value != 0) || value.length <= 0) {
    if (name) {
      return `The ${name} field is required`;
    }
    return "This field is required";
  }
  return true;
});

defineRule("email", (value) => {
  if (
    value &&
    !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(value)
  ) {
    return "Please enter valid email";
  }
  return true;
});

defineRule("phone", (value) => {
  if (value && !/^[0-9]{10}$/.test(value)) {
    return "The Phone Number field must be numeric and exactly contain 10 digits";
  }
  return true;
});

defineRule("minLength", (value, [limit]) => {
  if (value.length < limit) {
    return `This field must be at least ${limit} characters`;
  }
  return true;
});

defineRule("maxLength", (value, [limit]) => {
  if (value.length > limit) {
    return `This field must be almost ${limit} characters`;
  }
  return true;
});

defineRule("confirmed", (value, [target]) => {
  if (value === target) {
    return true;
  }
  return "Passwords must match";
});

defineRule("price", (value) => {
  if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
    return "Please enter valid value";
  }

  return true;
});

defineRule("number", (value) => {
  if (value && !/^[1-9]\d*(\d{1,2}?)?$/.test(value)) {
    return "Please enter valid value";
  }
  return true;
});

defineRule('gst', value => {
  if (value && !/^[A-Z0-9]{15}$/.test(value)) {
      return 'Please enter valid GST number';
  }
  return true;
});

defineRule("instagram", (value) => {
  if (
    value &&
    !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(
      value
    )
  ) {
    return "Please enter valid instagram url";
  }
  return true;
});

defineRule("facebook", (value) => {
  if (
    value &&
    !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(
      value
    )
  ) {
    return "Please enter valid facebook url";
  }
  return true;
});
defineRule("url", (value) => {
  if (
    value &&
    !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(
      value
    )
  ) {
    return "Please enter valid  url";
  }
  return true;
});

defineRule('url', value => {
  if (value && !/^((http|https):\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/g.test(value)) {
      return 'Please enter valid URL';
  }

  return true;
});


defineRule("fromDate", (value, [target]) => {
  if (!value || (target && target > value)) {
    return "Please enter valid value";
  }
  return true;
});

defineRule("toDate", (value, [target]) => {
  if (!value || (target && target < value)) {
    return "Please enter valid value";
  }
  return true;
});

defineRule("newdigit", (value) => {
  if (!/^[0-9]\d*?$/.test(value)) {
      return "Please enter numeric values only";
  }
  
  if ((value < 1)) {
      return "Please enter postive numeric values only";
  }
  return true;
});
defineRule("mobileNumber", (value) => {
  if (!value) {
    return true; 
  }
  const mobileNumberPattern = /^[0-9]{10}$/;
  if (!mobileNumberPattern.test(value)) {
    return 'Please enter a valid 10-digit mobile number';
  }
  return true;
});

