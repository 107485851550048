import {
  createStore
} from "vuex";
const store = createStore({
  state() {
    return {
      user: {},
      id: "",
      uuid: "",
      vehicleTab: "",
      orderTab: "",
      RatingTab: "",
      orderStatus: "",
      title: "",
      isHideMenu: false,
      vendorDetailsTab: "",
      vendorTab: "",
      parentId: "",
      subCategoryId: "",
      categoryAddType: "",
      subSubCategorId: "",
      vehicleId: "",
      categoryId: "",
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setId(state, payload) {
      state.id = payload;
    },
    setIsHideMenu(state, payload) {
      state.isHideMenu = payload;
    },
    setVehicleTab(state, payload) {
      state.vehicleTab = payload;
    },
    setUuid(state, payload) {
      state.uuid = payload;
    },
    setWorkshopId(state, payload) {
      state.workshopId = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    setOrderTab(state, payload) {
      state.orderTab = payload;
    },
    setRatingTab(state, payload) {
      state.RatingTab = payload;
    },
    setOrderStatus(state, payload) {
      state.orderStatus = payload;
    },
    setVendorDetailsTab(state, payload) {
      state.vendorDetailsTap = payload;
    },
    setUserDetailsTab(state, payload) {
      state.userDetailsTap = payload;
    },
    setVendorTab(state, payload) {
      state.vendorTab = payload;
    },
    setParentId(state, payload) {
      state.parentId = payload;
    },
    setSubCategoryId(state, payload) {
      state.subCategoryId = payload;
    },
    setCategoryAddType(state, payload) {
      state.categoryAddType = payload;
    },
    setSubSubCategorId(state, payload) {
      state.subSubCategorId = payload;
    },
    setVehicleId(state, payload) {
      state.vehicleId = payload;
    },
    setCategoryId(state, payload) {
      state.categoryId = payload;
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getTitle(state) {
      return state.title;
    },
    getId(state) {
      return state.id;
    },
    getIsHideMenu(state) {
      return state.isHideMenu;
    },
    getVehicleTab(state) {
      return state.vehicleTab;
    },
    getUuid(state) {
      return state.uuid;
    },
    getWorkshopId(state) {
      return state.workshopId;
    },
    getUserId(state) {
      return state.userId;
    },
    getOrderTab(state) {
      return state.orderTab;
    },

    getOrderStatus(state) {
      return state.orderStatus;
    },

    getVendorDetailsTab(state) {
      return state.vendorDetailsTap;
    },
    getUserDetailsTab(state) {
      return state.userDetailsTap;
    },
    getVendorTab(state) {
      return state.vendorTab;
    },
    getParentId(state) {
      return state.parentId;
    },
    getSubCategoryId(state) {
      return state.subCategoryId;
    },
    getCategoryAddType(state) {
      return state.categoryAddType;
    },
    getSubSubCategorId(state) {
      return state.subSubCategorId;
    },
    getRatingTab(state) {
      return state.RatingTab;
    },
    getVehicleId(state) {
      return state.vehicleId;
    },
    getCategoryId(state) {
      return state.categoryId;
    },
  },
  actions: {},
});

export default store;