<template>
  <router-view></router-view>
</template>

<script>
import './assets/css/style.css'
import './assets/css/font-awesome.min.css'
import './assets/css/sidebar.css'
import 'element-plus/theme-chalk/display.css'


export default {
  name: 'App',
  components: {
  }
}
</script>
