import { createWebHistory, createRouter } from "vue-router";
import { defineAsyncComponent } from "vue";

const MainView = defineAsyncComponent(() => import("@/views/main.vue"));

const Login = defineAsyncComponent(() =>
  import("@/views/Onboarding/login.vue")
);

const ForgotPassword = defineAsyncComponent(() =>
  import("@/views/Onboarding/forgot-password.vue")
);
const Home = defineAsyncComponent(() => import("@/views/Home/home.vue"));

const Vendor = defineAsyncComponent(() => import("@/views/Vendor/vendor.vue"));

const VendorDetails = defineAsyncComponent(() =>
  import("@/views/Vendor/vendor-details.vue")
);

const AddVendor = defineAsyncComponent(() =>
  import("@/views/Vendor/vendor-details.vue")
);

const Users = defineAsyncComponent(() => import("@/views/User/users.vue"));

const UsersDetail = defineAsyncComponent(() =>
  import("@/views/User/user-details.vue")
);

const UserOrdersDetail = defineAsyncComponent(() =>
  import("@/views/User/user-order-details.vue")
);

const Orders = defineAsyncComponent(() => import("@/views/Orders/orders.vue"));

const ManageOrders = defineAsyncComponent(() =>
  import("@/views/Orders/manage-orders.vue")
);

const AddUserVehicle = defineAsyncComponent(() =>
  import("@/views/User/add-user-vehicle.vue")
);

const UserProfile = defineAsyncComponent(() =>
  import("@/views/User/profile.vue")
);

const Roles = defineAsyncComponent(() => import("@/views/Roles/roles.vue"));

const AddRole = defineAsyncComponent(() =>
  import("@/views/Roles/add-role.vue")
);

const Brands = defineAsyncComponent(() => import("@/views/Brand/brand.vue"));

const AddBrand = defineAsyncComponent(() =>
  import("@/views/Brand/add-brand.vue")
);

const Banner = defineAsyncComponent(() => import("@/views/Banner/banner.vue"));

const AddBanner = defineAsyncComponent(() =>
  import("@/views/Banner/add-banner.vue")
);

const Category = defineAsyncComponent(() =>
  import("@/views/Category/categories.vue")
);

const AddCategory = defineAsyncComponent(() =>
  import("@/views/Category/add-category.vue")
);
const Cashback = defineAsyncComponent(() =>
  import("@/views/Cashback/cashback.vue")
);

const Setting = defineAsyncComponent(() =>
  import("@/views/Setting/setting.vue")
);

const RoadsideAssistance = defineAsyncComponent(() =>
  import("@/views/Roadside Assistance/roadside-assistance.vue")
);

const AddRoadsideAssistance = defineAsyncComponent(() =>
  import("@/views/Roadside Assistance/add-roadside-assistance.vue")
);

const Vehicle = defineAsyncComponent(() =>
  import("@/views/Vehicle/vehicle.vue")
);

const AdminAddVehicle = defineAsyncComponent(() =>
  import("@/views/Vehicle/add-vehicle.vue")
);

const Rating = defineAsyncComponent(() => import("@/views/Rating/rating.vue"));

const AdminAddRating = defineAsyncComponent(() =>
  import("@/views/Rating/add-rating.vue")
);

const Admin = defineAsyncComponent(() => import("@/views/Admin/admin.vue"));

const AddAdmin = defineAsyncComponent(() =>
  import("@/views/Admin/add-admin.vue")
);

const CouponCode = defineAsyncComponent(() =>
  import("@/views/CouponCode/couponcode.vue")
);

const AddCouponCode = defineAsyncComponent(() =>
  import("@/views/CouponCode/add-couponcode.vue")
);

const Invoice = defineAsyncComponent(() =>
  import("@/views/Invoice/invoice.vue")
);

const AddInvoice = defineAsyncComponent(() =>
  import("@/views/Invoice/add-invoice.vue")
);

const ChangePassword = defineAsyncComponent(() =>
  import("@/views/User/change-password.vue")
);

const Review = defineAsyncComponent(() => import("@/views/Review/review.vue"));

const AddReview = defineAsyncComponent(() =>
  import("@/views/Review/add-review.vue")
);

const AddFaq = defineAsyncComponent(() => import("@/views/Faq/add-faq.vue"));

const Faq = defineAsyncComponent(() => import("@/views/Faq/faq.vue"));

const AddManufacturer = defineAsyncComponent(() =>
  import("@/views/Vehicle/add-manufacturer.vue")
);

const AddManufacturerModel = defineAsyncComponent(() =>
  import("@/views/Vehicle/add-manufacturer-model.vue")
);

const AddFuel = defineAsyncComponent(() =>
  import("@/views/Vehicle/add-fuel.vue")
);

const AddTransmission = defineAsyncComponent(() =>
  import("@/views/Vehicle/add-transmission.vue")
);

const Chat = defineAsyncComponent(() => import("@/views/Chat/chat.vue"));

const Notification = defineAsyncComponent(() =>
  import("@/views/Notification/notification.vue")
);

const Report = defineAsyncComponent(() => import("@/views/Report/report.vue"));

const ChateDetails = defineAsyncComponent(() =>
  import("@/views/Chat/chat-details.vue")
);

const AddNotification = defineAsyncComponent(() =>
  import("@/views/Notification/add-notification.vue")
);

const ScheduleNotification = defineAsyncComponent(() =>
  import("@/views/Notification/schedule-notification.vue")
);

const OrderDetails = defineAsyncComponent(() =>
  import("@/views/Orders/order-details.vue")
);

const VendorRequests = defineAsyncComponent(() =>
  import("@/views/Vendor/vendor-requests.vue")
);

const StaticPage = defineAsyncComponent(() =>
  import("@/views/Static Page/static-page.vue")
);

const AddStaticPage = defineAsyncComponent(() =>
  import("@/views/Static Page/add-static-page.vue")
);

const HomeService = defineAsyncComponent(() =>
  import("@/views/HomeService/homeService.vue")
);

const AddHomeService = defineAsyncComponent(() =>
  import("@/views/HomeService/add-homeService.vue")
);

const Insurance = defineAsyncComponent(() =>
  import("@/views/Insurance/insurance.vue")
);

const AddInsurance = defineAsyncComponent(() =>
  import("@/views/Insurance/add-insurance.vue")
);

const SubCategory = defineAsyncComponent(() =>
  import("@/views/Category/sub-category.vue")
);

const VendorReviewPage = defineAsyncComponent(() =>
  import("@/views/Vendor/vendor-review-page.vue")
);

const SubSubCategory = defineAsyncComponent(() =>
  import("@/views/Category/sub-sub-category.vue")
);

const QuotationPage = defineAsyncComponent(() =>
  import("@/views/Quotations/quotations.vue")
);

const QuotationRequest = defineAsyncComponent(() =>
  import("@/views/Quotations/sent-quotation-request.vue")
);

const appSupportSetting = defineAsyncComponent(() =>
  import("@/views/Setting/app-support-setting.vue")
);

const RequestCallback = defineAsyncComponent(() =>
  import("@/views/RequestCallback/request-callback.vue")
);

const AddAddress = defineAsyncComponent(() =>
  import("@/views/User/add-address.vue")
);

const OrderFlow = defineAsyncComponent(() =>
  import("@/views/Order-Flow/order-flow.vue")
);

const InventoryList = defineAsyncComponent(() =>
  import("@/views/Inventory/inventory.vue")
);

const InventoryDetails = defineAsyncComponent(() =>
  import("@/views/Inventory/inventory-details.vue")
);

const SubCategoryStep = defineAsyncComponent(() =>
  import("@/views/Category/sub-category-step.vue")
);
const SubCategoryStepDetails = defineAsyncComponent(() =>
  import("@/views/Category/sub-category-step-details.vue")
);
const SubCategoryDetails = defineAsyncComponent(() =>
  import("@/views/Category/sub-category-details.vue")
);
const AddSubCategoriesDetails = defineAsyncComponent(() =>
  import("@/views/Category/add-sub-category-details.vue")
);
const BannerView = defineAsyncComponent(() =>
  import("@/views/Category/category-banner.vue")
);
const routes = [
  {
    path: "/",
    redirect: Login,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "",
    name: "Main",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/profile",
        name: "User Profile",
        component: UserProfile,
      },
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/users",
        name: "User",
        component: Users,
      },
      {
        path: "/user-details",
        name: "User Details",
        component: UsersDetail,
      },
      {
        path: "/user-order-details/:id",
        name: "User Order Details",
        component: UserOrdersDetail,
      },
      {
        path: "/vendors",
        name: "Vendor",
        component: Vendor,
      },
      {
        path: "/vendor-details",
        name: "Vendor Details",
        component: VendorDetails,
      },
      {
        path: "/add-vendor",
        name: "Add Vendor",
        component: AddVendor,
      },
      {
        path: "/orders",
        name: "Orders",
        component: Orders,
      },
      {
        path: "/order-details",
        name: "OrdersDetails",
        component: OrderDetails,
      },
      {
        path: "/ManageOrders",
        name: "Manage Orders",
        component: ManageOrders,
      },
      {
        path: "/add-user-vehicle",
        name: "Add User Vehicle",
        component: AddUserVehicle,
      },
      {
        path: "/roles",
        name: "Roles",
        component: Roles,
      },
      {
        path: "/brands",
        name: "Brands",
        component: Brands,
      },
      {
        path: "/add-brand",
        name: "Add Brand",
        component: AddBrand,
      },
      {
        path: "/update-brand",
        name: "Update Brand",
        component: AddBrand,
      },
      {
        path: "/banners",
        name: "Banner",
        component: Banner,
      },
      {
        path: "/add-banner",
        name: "Add Banner",
        component: AddBanner,
      },
      {
        path: "/update-banner",
        name: "Update Banner",
        component: AddBanner,
      },
      {
        path: "/categories",
        name: "Category",
        component: Category,
      },
      {
        path: "/add-category",
        name: "Add Category",
        component: AddCategory,
      },
      {
        path: "/update-category",
        name: "Update Category",
        component: AddCategory,
      },
      {
        path: "/cashbacks",
        name: "Cashback",
        component: Cashback,
      },
      {
        path: "/settings",
        name: "Setting",
        component: Setting,
      },
      {
        path: "/roadside_assistance",
        name: "RoadsideAssistance",
        component: RoadsideAssistance,
      },
      {
        path: "/add-roadside-assistance",
        name: "AddRoadsideAssistance",
        component: AddRoadsideAssistance,
      },
      {
        path: "/update-roadside-assistance",
        name: "UpdateRoadsideAssistance",
        component: AddRoadsideAssistance,
      },

      {
        path: "/vehicles",
        name: "Vehicle",
        component: Vehicle,
      },
      {
        path: "/add-vehicle",
        name: "AdminAddVehicle",
        component: AdminAddVehicle,
      },
      {
        path: "/rating",
        name: "Rating",
        component: Rating,
      },
      {
        path: "/add-rating",
        name: "AdminAddRating",
        component: AdminAddRating,
      },
      {
        path: "/update-rating",
        name: "AdminUpdateRating",
        component: AdminAddRating,
      },
      {
        path: "/update-vehicle",
        name: "AdminUpdateVehicle",
        component: AdminAddVehicle,
      },
      {
        path: "/admins",
        name: "Admin",
        component: Admin,
      },
      {
        path: "/add-admin",
        name: "AddAdmin",
        component: AddAdmin,
      },
      {
        path: "/update-admin",
        name: "UpdateAdmin",
        component: AddAdmin,
      },
      {
        path: "/coupon_code",
        name: "CouponCode",
        component: CouponCode,
      },
      {
        path: "/add-coupon_code",
        name: "AddCouponCode",
        component: AddCouponCode,
      },
      {
        path: "/update-coupon_code",
        name: "UpdateCouponCode",
        component: AddCouponCode,
      },

      {
        path: "/invoice",
        name: "Invoice",
        component: Invoice,
      },
      {
        path: "/add-saccode",
        name: "AddInvoice",
        component: AddInvoice,
      },

      {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
      },
      {
        path: "/reviews",
        name: "Review",
        component: Review,
      },
      {
        path: "/add-review",
        name: "AddReview",
        component: AddReview,
      },
      {
        path: "/update-review",
        name: "UpdateReview",
        component: AddReview,
      },
      {
        path: "/faq",
        name: "Faq",
        component: Faq,
      },
      {
        path: "/add-faq",
        name: "AddFaq",
        component: AddFaq,
      },
      {
        path: "/update-faq",
        name: "UpdateFaq",
        component: AddFaq,
      },

      {
        path: "/update-manufacturer",
        name: "UpdateManufacturer",
        component: AddManufacturer,
      },
      {
        path: "/add-manufacturer",
        name: "addManufacturer",
        component: AddManufacturer,
      },
      {
        path: "/add-manufacturer-model",
        name: "addManufacturerModel",
        component: AddManufacturerModel,
      },
      {
        path: "/update-manufacturer-model",
        name: "updateManufacturerModel",
        component: AddManufacturerModel,
      },
      {
        path: "/add-fuel",
        name: "addFuel",
        component: AddFuel,
      },
      {
        path: "/update-fuel",
        name: "updateFuel",
        component: AddFuel,
      },
      {
        path: "/add-transmission",
        name: "addTransmission",
        component: AddTransmission,
      },
      {
        path: "/update-transmission",
        name: "updateTransmission",
        component: AddTransmission,
      },
      {
        path: "/add-role",
        name: "AddRole",
        component: AddRole,
      },
      {
        path: "/update-role",
        name: "UpdateRole",
        component: AddRole,
      },
      {
        path: "/chats",
        name: "ChatMessage",
        component: Chat,
      },
      {
        path: "/notifications",
        name: "NotificationPage",
        component: Notification,
      },
      {
        path: "/reports",
        name: "ReportPage",
        component: Report,
      },
      {
        path: "/chat-details",
        name: "ChateDetails",
        component: ChateDetails,
      },
      {
        path: "/add-notification",
        name: "AddNotification",
        component: AddNotification,
      },
      {
        path: "/schedule-notification",
        name: "ScheduleNotification",
        component: ScheduleNotification,
      },
      {
        path: "/vendor_requests",
        name: "VendorRequests",
        component: VendorRequests,
      },
      {
        path: "/static_pages",
        name: "StaticPage",
        component: StaticPage,
      },
      {
        path: "/add-static-page",
        name: "AddStaticPage",
        component: AddStaticPage,
      },
      {
        path: "/update-static-page",
        name: "UpdateStaticPage",
        component: AddStaticPage,
      },

      {
        path: "/insurance",
        name: "Insurance",
        component: Insurance,
      },
      {
        path: "/add-insurance",
        name: "AddInsurance",
        component: AddInsurance,
      },
      {
        path: "/update-insurance",
        name: "UpdateInsurance",
        component: AddInsurance,
      },

      {
        path: "/sub-category",
        name: "SubCategory",
        component: SubCategory,
      },
      {
        path: "/vendor-review",
        name: "VendorReviewPage",
        component: VendorReviewPage,
      },
      {
        path: "/sub-sub-category",
        name: "SubSubCategory",
        component: SubSubCategory,
      },
      {
        path: "/quotations",
        name: "QuotationsPage",
        component: QuotationPage,
      },
      {
        path: "/sent-quotation-request",
        name: "QuotationRequest",
        component: QuotationRequest,
      },
      {
        path: "/app-support",
        name: "appSupportSetting",
        component: appSupportSetting,
      },
      {
        path: "/request-callback",
        name: "RequestCallback",
        component: RequestCallback,
      },
      {
        path: "/add-address",
        name: "AddAddress",
        component: AddAddress,
      },

      {
        path: "/home-service",
        name: "HomeService",
        component: HomeService,
      },
      {
        path: "/add-home-service",
        name: "Add HomeService",
        component: AddHomeService,
      },
      {
        path: "/update-home-service",
        name: "Update HomeService",
        component: AddHomeService,
      },
      {
        path: "/order-flow",
        name: "OrderFlow",
        component: OrderFlow,
      },
      {
        path: "/inventory",
        name: "inventory-list",
        component: InventoryList,
      },
      {
        path: "/update-inventory",
        name: "update-inventory",
        component: InventoryDetails,
      },
      {
        path: "/add-inventory",
        name: "add-inventory",
        component: InventoryDetails,
      },
      {
        path: "/sub-category/step",
        name: "sub-category-step",
        component: SubCategoryStep,
      },
      {
        path: "/add-sub-category-step",
        name: "add-sub-category-step",
        component: SubCategoryStepDetails,
      },
      {
        path: "/update-sub-category-step",
        name: "update-sub-category-step",
        component: SubCategoryStepDetails,
      },
      {
        path: "/sub-categories/details",
        name: "SubCategoriesDetails",
        component: SubCategoryDetails,
      },
      {
        path: "/add-sub-category-details",
        name: "add-sub-category-step-details",
        component: AddSubCategoriesDetails,
      },
      {
        path: "/update-sub-category-details",
        name: "update-sub-category-details",
        component: AddSubCategoriesDetails,
      },
      {
        path: "/category-banner",
        name: "BannerView",
        component: BannerView,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token") == null) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    if (to.path === "/" || to.path === "/login") {
      if (localStorage.getItem("access_token") != null) {
        next({
          path: "/home",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
